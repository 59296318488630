import request from "@/utils/request"

// 内容维度-头部统计数据
export function getInformationHeaderNum(data) {
  return request({
    url: "/label/getInformationHeaderNum",
    method: "post",
    data
  })
}

// 内容维度列表 /label/selectInformationAnalysis
export function selectInformationAnalysis(data) {
  return request({
    url: "/label/selectInformationAnalysis",
    method: "post",
    data
  })
}

// 企业维度列表 /label/selectCompanyAnalysisPage
export function selectCompanyAnalysisPage(data) {
  return request({
    url: "/label/selectCompanyAnalysisPage",
    method: "post",
    data
  })
}

// 顾问维度列表 /label/selectAdviserAnalysisPage
export function selectAdviserAnalysisPage(data) {
  return request({
    url: "/label/selectAdviserAnalysisPage",
    method: "post",
    data
  })
}

// 内容维度-头部详情 /label/selectInformationDetail
export function selectInformationDetail(query) {
  return request({
    url: "/label/selectInformationDetail",
    method: "get",
    params: query
  })
}

// 内容维度-资讯推送企业列表 /label/selectInformationAnalysisPage
export function selectInformationAnalysisPage(data) {
  return request({
    url: "/label/selectInformationAnalysisPage",
    method: "post",
    data
  })
}

// 内容维度-资讯推送企业列表-用户数 /label/selectInformationAnalysisUserPage
export function selectInformationAnalysisUserPage(data) {
  return request({
    url: "/label/selectInformationAnalysisUserPage",
    method: "post",
    data
  })
}

// 内容维度-资讯推送企业列表-用户分享数
export function selectInformationAnalysisUserSharePage(data) {
  return request({
    url: "/label/selectInformationAnalysisUserSharePage",
    method: "post",
    data
  })
}

// 内容维度-资讯推送企业列表-用户查看数
export function selectInformationAnalysisUserViewPage(data) {
  return request({
    url: "/label/selectInformationAnalysisUserViewPage",
    method: "post",
    data
  })
}

// 获取企业头部详情 serverCompany/companyDetail
export function companyDetail(query) {
  return request({
    url: "/serverCompany/companyDetail",
    method: "get",
    params: query
  })
}

// 企业维度-推送内容列表 /label/selectCompanyAnalysisInformation
export function selectCompanyAnalysisInformation(data) {
  return request({
    url: "/label/selectCompanyAnalysisInformation",
    method: "post",
    data
  })
}